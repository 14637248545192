import React, { useEffect, useState } from 'react';
import { getPressRelease } from '../../../get_server_query';
import { format } from '../../components/cms-modules';
import PressReleasedDetail from '../../components/newsroom/pressRelease';
import { useLocale } from '../../context/locale.context';
import Layout from '../../modules/layout';
import SEO from '../../modules/seo';

const PreviewPage = () => {
  const { locale } = useLocale();
  const [page, setPage] = useState(null);
  const gqlUrl = `${process.env.GATSBY_CONTENTFUL_GRAPHQL_URL}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`;
  const authHeader = `Bearer SOEGLctP3y-WLjmF6YIv1juHhNn9dOBOvp98vtQuFnY`;

  useEffect(() => {
    const url = new URL(window.location);
    const fetchPage = async () => {
      const res = await fetch(gqlUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: null,
          query: getPressRelease(url.searchParams.get('componentId'), locale),
          variables: {},
        }),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
        },
      });

      const json = await res.json();
      setPage(format(json.data.pressRelease));
    };
    fetchPage();
  }, []);

  return page ? (
    <Layout
      hasWhiteNavigation={page.hasWhiteNavigation}
      heroTeaser={page.heroTeaser}
      preview
    >
      <SEO title={page.headline} />
      <PressReleasedDetail data={page}></PressReleasedDetail>
    </Layout>
  ) : null;
};

export default PreviewPage;
